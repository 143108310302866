<template>
  <div>
    <b-card>
      <b-card-title class="mb-1">
        <h2>
           Liquidación Proyecto #{{project.projectCode}}        
        </h2>
      </b-card-title>

      <b-card-body class="mt-1">
        <b-row  style="justify-content: right;">
          <b-col v-if="!processing && !mainProcessing" md="3" xl="3">
            <b-button  variant="warning" block @click="openDefineInvestorsValueModal()">
                <feather-icon icon="DollarSignIcon" class="mr-50"/> 1. Definir Liquidación
            </b-button> 
          </b-col>
          <b-col v-if="!processing && !mainProcessing" md="3" xl="3">
            <b-button  variant="primary" block @click="saveAllProjectsSettlements()" >
                <feather-icon icon="SaveIcon" class="mr-50"/> 2. Guardar Información
            </b-button> 
          </b-col>
          <b-col v-if="!processing && !mainProcessing" md="3" xl="3">
            <b-button  variant="info" block @click="loadBalances()" >
                <feather-icon icon="UploadIcon" class="mr-50"/> 3. Actualizar Balances
            </b-button> 
          </b-col>
          <b-col v-if="!processing && !mainProcessing" md="3" xl="3">
            <b-button  variant="danger" block @click="showProjectSettlementDetailsModal()" >
                <feather-icon icon="MailIcon" class="mr-50"/> 4. Notificar Liquidación
            </b-button> 
          </b-col>
          
          <b-col v-if="processing" md="3" xl="3">
            <b-button variant="primary" disabled block>
              <b-spinner small label="Cargando..." /></b-button>
          </b-col>
        </b-row>

        <b-row class="mb-1 mt-2">
          <b-col style="padding-left:0px;" md="2" xl="2">
            <h4>Email</h4>
          </b-col>
          <b-col style="padding-left:0px" md="2" xl="2" >
            <h4>Nombre</h4>
          </b-col>
          <b-col style="padding-left:0px" md="1" xl="1">
            <h4>Cedula/NIT</h4>
          </b-col>
          <b-col style="padding-left:0px" md="6" xl="6">
            <b-row>
              <b-col md="3" xl="3">
                <h4>Monto Invertido</h4>           
              </b-col>
              <b-col md="3" xl="3">
                <h4>Cargar Saldo</h4>
              </b-col>
              <b-col style="padding-left:0px" md="3" xl="3">
                <h4>Monto Recibido</h4>
              </b-col>
            </b-row>                        
          </b-col>
        </b-row> 

        <b-row style="justify-content:center">
          <b-col v-if="mainProcessing" md="2" xl="2" class="mb-1 mt-2">
              <b-row>
                <b-spinner variant="primary" large label="Cargando..."/><h4 style="color:primary; margin-top:5px; margin-left:10px">Cargando...</h4>
              </b-row>              
          </b-col>
        </b-row>

        <b-col md="12" xl="12">          
          <b-row style="align-items:center" v-for="item in investmentsApprovedToSettlement" :key="item.investmendID">
            <b-col  md="2" xl="2" class="mb-1 mr-1" style="word-break: break-all;">            
              <b-row>{{item.email}}</b-row>            
            </b-col>
            
            <b-col  md="2" xl="2" class="mb-1">
              <b-row>{{item.fullName }} </b-row>
            </b-col>

            <b-col v-if="item.documentNumber" md="1" xl="1" class="mb-1">
              <b-row>{{item.documentNumber}} </b-row>              
            </b-col>            
            <b-col v-else md="1" xl="1" class="mb-1">
              <b-row></b-row>              
            </b-col>            
            
            <b-col md="3" xl="3" class="mb-1" style="padding-left:0px">              
              <b-row>
                <b-col md="6" xl="6" >
                  $ {{parseFloat(item.amountInvested).toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} ({{item.participationPercent.toFixed(3)}}%) 
                </b-col>
                <b-col md="1" xl="1">
                  <b-form-checkbox v-model="item.allowedToLoadBalance" @change="saveLoadBalanceCheck(item)" checked="false" name="check-button" switch inline></b-form-checkbox>
                </b-col>                
              </b-row>
            </b-col>

            <b-col md="2" lg="2" xl="2" style="padding-left:0" class="mb-1">
              <b-row>
                <!-- <b-col md="1" lg="1" xl="1">
                  <feather-icon v-if="item.balanceLoaded" size="18" icon="CheckIcon" style="color:#00bd56; margin-top: 10px;" />
                </b-col> -->
                <b-col md="9" lg="9" xl="9">
                  <b-input-group >
                    <b-input-group-prepend is-text >
                      <feather-icon size="18" icon="DollarSignIcon" style="color:#00bd56" />
                    </b-input-group-prepend>
                    <cleave 
                      style="margin-left:0;"                        
                      id="vi-first-name"
                      v-model.number="item.amountReceived"
                      value-field="item.amountReceived"
                      class="form-control "
                      :options="options.number" 
                      placeholder="Digita el valor" 
                      ref="myTextField"/>
                  </b-input-group>
                </b-col>
                <b-col md="1" lg="1" xl="1" v-b-tooltip.hover.top="'Dinero Cargado al Saldo'" >
                  <feather-icon v-if="item.balanceLoaded" size="18" icon="CheckCircleIcon" style="color:#00bd56; margin-top: 10px;" />
                </b-col>
                <b-col md="1" lg="1" xl="1" v-b-tooltip.hover.top="'Notificación Enviada'">
                  <feather-icon v-if="item.liquidationNotificationSent == true"  size="18" icon="MailIcon" style="color: #00bd56; margin-top: 10px;" />
                </b-col>
              </b-row>
            </b-col>
            
            <b-col v-if="!processing" md="1" xl="1" class="mb-1" style="display:flex;">
              <b-button v-b-tooltip.hover.top="'Marcar Documentos Válidos'" size="sm" v-if="item.validDocuments != true" class="mr-1" variant="outline-primary" @click="markValidDocuments(item)" >
                  <feather-icon icon="CheckIcon"/>
              </b-button> 
              <b-button v-b-tooltip.hover.top="'Guardar Información'" size="sm" class="mr-1" variant="outline-primary" @click="saveProjectSettlement(item)" >
                  <feather-icon icon="SaveIcon"/>
              </b-button> 
              <b-button v-b-tooltip.hover.top="'Descargar RUT'" size="sm" v-if="item.rutUrl" variant="outline-primary" @click="downloadRut(item)" >
                  <feather-icon icon="DownloadIcon"/>
              </b-button> 
            </b-col>
            <b-col v-if="processing" md="1" xl="1" class="mb-1" >
              <b-button variant="outline-primary" disabled block>
                <b-spinner small label="Cargando..." /></b-button>
            </b-col>
          </b-row>
         
        </b-col>        
      </b-card-body>
    </b-card>
    <b-modal ref="defineInvestorsValueModal" hide-footer title="Digite Total Dinero Neoganaderos">
          <b-input-group >
            <b-input-group-prepend is-text >
              <feather-icon size="18" icon="DollarSignIcon" style="color:#00bd56" />
            </b-input-group-prepend>
            <cleave 
              style="margin-left:0;"                        
              id="vi-first-name"
              v-model.number="investorsTotalAmount"
              value-field="investorsTotalAmount"
              class="form-control "
              :options="options.number" 
              placeholder="Digita el valor"/>
            </b-input-group>
        <b-button class="mt-1" variant="primary" block @click="assignInvestorsValues">
            <feather-icon class="mr-50"/>
            <span class="align-middle">Aceptar</span>
        </b-button>         
    </b-modal>

    <b-modal ref="projectSettlementDetailsModal" hide-footer title="Información Adicional de Liquidación" centered>

      <b-col md="12" xl="12" class="mb-1">
          <h5 class="mb-1">Escribir/editar información de liquidación de Proyecto:</h5>
          <b-form-textarea v-model="liquidationObservations" placeholder="Digite" rows="5"/>
      </b-col> 
      <b-col md="12" xl="12" class="mb-1">      
        <b-button class="mt-1" variant="primary" block @click="sendProjectSettlementDetailsNotification()">
            <feather-icon class="mr-50"/>
            <span class="align-middle">Notificar</span>
        </b-button>         
      </b-col>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import {
  BRow, BCol, BTable,BCard, BInputGroupPrepend, BCardBody, BLink, BMedia, BFormGroup, 
  BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BFormTextarea, BButton, BSpinner,
  BModal, VBTooltip,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import axios from '@axios'
import { email } from 'vee-validate/dist/rules'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardCode,
    BCardBody,
    BTable,
    BLink,
    BFormGroup,
    BFormTextarea,
    BMedia,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,    
    BSpinner,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {            
      projectID: null,      
      investmentsApprovedToSettlement:[],
      amountReceived:null,
      investmendID: null,
      totalValue: 0,
      disabled: false,      
      options: {
          date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
          },
          number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          },
      }, 
      project: {},  
      processing: false,  
      mainProcessing: false,
      investorsTotalAmount: 0,
      allowedToLoadBalance: null,
      validDocuments: null,
      liquidationObservations: null,
    }
  },
  created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.projectID != null) {
      this.projectID = this.$route.query.projectID; 
      this.GetProjectById();
    }
  },
  watch:{    
    amountReceived: function(val) { item.amountReceived = val; },  
    validDocuments: function(val) { item.validDocuments = val; },   
    liquidationObservations: function(val) { liquidationObservations = val; },    
  },
  methods: {
    GetinvestmentsApprovedToSettlementByProjectID(){
      this.mainProcessing = true
      axios.get('/Investment/GetInvestmentsApprovedByProjectID',  { params: { projectID: this.projectID }})      
      .then(response => {   
        this.mainProcessing = false;
        this.investmentsApprovedToSettlement = response.data;
        if(this.investmentsApprovedToSettlement.length > 0){
          var $this = this;
          this.investmentsApprovedToSettlement.forEach(function(item, index){  
            item.participationPercent = parseFloat((item.amountInvested*100)/$this.project.totalMoneyCollected);

            if(item.allowedToLoadBalance == null){
              item.allowedToLoadBalance = true
            }
            if(item.amountReceived == null){
              item.amountReceived = 0
            }else{
              item.amountReceived = parseFloat(item.amountReceived).toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")            
            }              
          })
        }
      })
    },
    GetProjectById(){
      axios.get('/Project/GetProjectById',  { params: { projectID: this.projectID }})
      .then(response => {
        this.project = response.data;
        this.liquidationObservations = this.project.liquidationObservations
        this.GetinvestmentsApprovedToSettlementByProjectID()
      })
    },
    saveProjectSettlement(item){ 
      this.processing = true;
      if(item.amountReceived){                              
        axios.get('/Investment/SaveProjectSettlement',  { params: { investmentID: item.investmendID, amountReceived: item.amountReceived, allowedToLoadBalance: item.allowedToLoadBalance }})
        .then(response => {                                    
          this.processing = false;          
          if(response){                      
            this.investmentsApprovedToSettlement.push(this.investmentsApprovedToSettlement.splice(item,1)[0])          
          }
        })
      } else {
        this.processing = false;   
        this.$bvToast.toast("No digitaste ningún campo", { title: 'Error', variant: 'danger', solid: true, })     
      }
    },
    downloadRut(item){ 
      window.open(item.rutUrl, '_blank');
    },
    saveAllProjectsSettlements(){
      this.processing = true;      
      this.investmentsApprovedToSettlement.forEach(function(item, index){  
        if(!item.amountReceived){          
          item.amountReceived = 0
        }
        if(item.allowedToLoadBalance == null){
          item.allowedToLoadBalance = true
        }
      })
      var payload = {
        investmentsApprovedToSettlement : this.investmentsApprovedToSettlement,
        TotalValue: this.totalValue
      }
      axios.post('/Investment/SaveInvestmentsApprovedToSettlement', payload)
      .then(response =>{
        this.processing = false;
        if(response.data){
          this.$bvToast.toast("Información guardada exitosamente", { title: 'Ok', variant: 'success', solid: true, })          
        }else{
          this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })
        } 
      })      
    },
    openDefineInvestorsValueModal(){
      this.$refs['defineInvestorsValueModal'].show();
    },
    assignInvestorsValues(){
      var $this = this;
      var uvtX92 = 3496368;
      var uvtX01 = 38004;
      var uvtComision = 0.2
      
      this.investmentsApprovedToSettlement.forEach(function(item, index){  
        item.amountReceived = Math.round(parseFloat($this.investorsTotalAmount*item.participationPercent/100));
        item.amountReceived = (parseFloat(item.amountReceived - item.amountReceived*4/1000))
        
        if(item.amountReceived > uvtX92){          
          item.amountReceived = parseFloat(item.amountReceived - item.amountReceived*uvtComision/100)                    
        }
        item.amountReceived = parseFloat(item.amountReceived).toFixed(0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")    
      })
      this.totalValue = $this.investorsTotalAmount;
      this.$refs['defineInvestorsValueModal'].hide();
    },
    loadBalances(){       
      this.mainProcessing = true     
      axios.get('/Investment/LoadBalances', {params: {projectID: this.project.projectID}})
      .then(response =>{
        this.mainProcessing = false;
        if(response){
            this.$bvToast.toast("Los Balances han sido cargados", { title: 'Ok', variant: 'success', solid: true, });              
        } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Wrong', variant: 'danger', solid: true, })     
        }
      }).catch(error => {
        this.mainProcessing = false;
        if(error.response){
            this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
        } else {
            this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
        }
      })              
    },
    saveLoadBalanceCheck(item){           
      axios.get('/Investment/SaveLoadBalanceCheck', {params: {investmentID: item.investmendID, check: item.allowedToLoadBalance}})
      .then(response =>{        
        if(response){
            this.$bvToast.toast("Información guardada", { title: 'Ok', variant: 'success', solid: true, });              
        } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Wrong', variant: 'danger', solid: true, })     
        }
      })
    },
    markValidDocuments(item){            
      item.validDocuments = true;
      axios.get('/User/MarkValidDocuments',  { params: { userID: item.userID}})
      .then(response =>{
          if(response){            
            this.$bvToast.toast("Información Actualizada", { title: 'Retenedor en la fuente', variant: 'success', solid: true})  
            window.location.reload()                       
          } else{
              this.$bvToast.toast("Hubo un problema", { title: 'Ok', variant: 'danger', solid: true})
          }               
      }) 
    },
    showProjectSettlementDetailsModal(){
      this.$refs['projectSettlementDetailsModal'].show();
    },
    hideProjectSettlementDetailsModal(){
      this.$refs['projectSettlementDetailsModal'].hide();
    },
    sendProjectSettlementDetailsNotification(){

      this.hideProjectSettlementDetailsModal()

      var payload = {
        projectID: this.projectID,
        liquidationObservations: this.liquidationObservations
      }
      axios.post('/Project/SendProjectSettlementDetailsNotification', payload)
      .then(response =>{
        this.processing = false;
        if(response.data){
          this.$bvToast.toast("Información enviada exitosamente", { title: 'Ok', variant: 'success', solid: true, })  
          window.location.reload()         
        }else{
          this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })
        } 
      })
    }
  }  
}
</script>
